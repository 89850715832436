.jply_linkpanel {
  display: block;
  width: 280px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e6e9;
  background-color: #ffffff;
}
.link_group {
  box-sizing: border-box;
  padding: 12px 0;
  a {
    display: block;
    box-sizing: border-box;
    padding: 8px 20px;
    line-height: 22px;
    font-size: 15px;
    color: $gray500;
    background: #fff;
    &:hover {
      background: #f3f3f4;
      color: $gray800;
    }
  }
  .ct_link_disable{
    display: block;
    box-sizing: border-box;
    padding: 8px 20px;
    line-height: 22px;
    font-size: 15px;
    color: $gray500;
    background: #fff;
    opacity: 0.3;
  }
}

.b2b_bi_logo {
  margin: 15px 0;
  position: relative;
  height: 20px;
  display: block;
  img {
    height: 100%;
  }
}

.b2b_gnb {
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 0 0 #e5e6e9;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .gnb_row {
    width: 100%;
  }
  .row_wrap {
    display:flex;
    width: 1060px;
    margin: 0 auto;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .b2b_total_category {
    position: absolute;
    z-index: 4;
    width: 100%;
    top: 100%;
    left: 0;
    border-top: solid 1px #e5e6e9;
    background-color: #fff;
    box-shadow: 0 18px 14px 0px rgba(0, 0, 0, 0.1);
  }
}

.gnb_head {
  position: relative;
  z-index: 3;
  height: 44px;
  box-sizing: border-box;
}

.gnb_body {
  position: relative;
  z-index: 2;
  .jp_admin_sslogo {
    overflow: hidden;
    display: block;
    font-size: 0;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: solid 1px #e5e6e9;

    img {
      width: 100%;
    }
  }
}
.gnb_foot {
  .b2b_total_category,
  .b2b_copyright {
    background-color: #f9f9fb;
  }
}

.info_item_more {
  font-size: 15px;
}

.b2b_category {
  position: relative;
  .ct_dp1 {
    display: block;
    float: left;
    height: 64px;
    position: relative;
    margin-right: 30px;
    &:hover,
    &.show {
      .ct_dp2 {
        display: block;
      }
    }
    &.active {
      position: relative;
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: -1px;
        width: 100%;
        height: 2px;
        background-color: $green500;
      }
      & > a {
        text-decoration: none;
      }
    }
    & > a {
      font-size: 16px;
      color: $gray700;
      line-height: 64px;
      font-weight: 500;
      &:hover {
        text-decoration: none;
        color: $green500;
      }
    }
    & > .ct_link_disable{
      font-size: 15px;
      color: $gray500;
      line-height: 64px;
      opacity: 0.3;
    }
  }

  .ct_dp2 {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
  }
  .label_new {
    margin-left: 2px;
    vertical-align: text-top;
    display: inline-block;
    width: 9px;
    height: 8px;
    background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/794/ic_gnb_new_green500_2x.png") no-repeat;
    background-size: 9px 8px;
  }
}

.b2b_moreinfo {
  height: 100%;
  display:flex;
  flex-grow: 1;
  position: relative;
  margin-left:20px;
  margin-top: 4px;
  .btn_total_menu {
    overflow: hidden;
    text-indent: -1000px;
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 64px;
    margin-right: 8px;
    border: none;
    background: transparent url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/637/ic_all_menu_gray500_sm.svg") 50% 50% no-repeat;
    &:hover {
      background: transparent url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/638/ic_all_menu_green500_sm.svg") 50% 50% no-repeat;
    }
  }
  .alarm_area {
    position: relative;
    display:flex;
    align-items: center;
    margin-left:auto;
    margin-right:8px;
    height: 50px;
    &.show_list {
      .btn_alarm {
        border-radius: 6px;
        background: $gray50;
      }
      .b2b_alarm_list {
        display: block;
      }
    }
    .btn_alarm {
      position: relative;
      height:32px;
      width:32px;
      background:none;
      border: 0;
      padding-top: 4px;
      i {
        font-size:16px;
        color:#85878c;
      }
      span {
        font-size: 0;
        line-height:0;
      }
      &.new {
        &:after {
          content:"";
          position: absolute;
          right:4px;
          top:3px;
          width:8px;
          height:8px;
          border-radius:50%;
          background: $green500;
        }
      }
    }
    .b2b_alarm_list {
      position: absolute;
      width:360px;
      top:48px;
      right:0;
      padding-top: 16px;
      display: none;
      #notification_spinner {
        position: relative;
        top: 1px;
        left: 0;
        border-radius: 6px;
        width: 100%;
        background: #fff;
        height: 100px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
        .loading_spinner_img {
          position: relative;
          top: 10px;
          left: 0;
          margin: 0 auto;
        }
        .loading_spinner_bg {
          position: absolute;
        }
        .bouncing-loader__round{
          width: 10px;
          height: 10px;
        }
      }
      .inner {
        background: #fff;
        border: 1px solid $gray100;
        border-radius:6px;
        padding:16px 0 20px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
      }
      .nodata {
        text-align: center;
        tab-size: 16px;
      }
      li {
        &:hover {
          background:$gray50;
        }
        a {
          display: block;
          padding:16px 20px;
        }
        .cont {
          position: relative;
          padding-left:36px;
        }
        .alarm_logo {
          position: absolute;
          left:0;
          top:0;
          width:24px;
          height:24px;
          border-radius:5px;
          overflow:hidden;
          background:url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/1605/img_noti_pool_1910.png');
          background-size: 100% auto;
        }
        .tit {
          font-size: 14px;
          color: $gray500;
          line-height: 22px;
          margin-bottom: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height:44px;
        }
        .txt {
          font-size: 12px;
          color:$gray200;
          line-height:20px;
        }
      }
      .view_all {
        margin-top: 20px;
        text-align:center;
        button {
          margin-left: 20px;
        }
      }
    }
  }
}

.jply_b2b_set {
  position: absolute;
  z-index: 100;
  padding-top: 9px;
  top: 48px;
  right: 0;
  display: none;
}

.ct_dp1 {
  & > a:focus ~ .ct_dp2 {
    display: block;
  }
}

.admin_area {
  position: relative;
  float: left;
  &:hover,
  &:focus,
  &.show {
    .jply_b2b_set {
      display: block;
    }
    .btn_myinfo {
      background-color: #f3f3f4;
    }
  }
  .btn_myinfo {
    position: relative;
    width: 65px;
    height: 48px;
    border-radius: 6px;
    background-color: #fff;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/640/ic_gnb_usermenu_arrow_down_gray200.svg");
    background-position: 47px 50%;
    background-repeat: no-repeat;
    border-color: transparent;
    box-sizing: border-box;
    &:focus ~ .jply_b2b_set {
      display: block;
    }
    .jp_admin_sslogo {
      position: absolute;
      left: 8px;
      top: 8px;
    }
  }
  .info_group {
    padding: 20px 20px;
    border-bottom: solid 1px #e5e6e9;
    li {
      display: block;
    }
    .power {
      font-size: 0;
      & > span {
        display: inline-block;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #f3f3f4;
        font-size: 11px;
        font-weight: bold;
        color: $gray500;
      }
      .premium {
        color: $green500;
        background: #f0fff7;
      }
      .standard {
        color: $blue500;
        background: #f2f9ff;
      }
      margin-bottom: 10px;
    }
    .power_company {
      word-break: break-all;
      line-height: 19px;
      color: $gray500;
      margin-bottom: 4px;
    }
    .power_name {
      line-height: 22px;
      font-size: 15px;
      font-weight: bold;
      color: $gray800;
      word-break: break-all;
    }
  }
}

.b2b_total_category {
  position: relative;
  z-index: 1;
  .b2b_gnb_wrapper {
    position: relative;
    padding: 32px 0 40px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .total_ct_dp1 {
    float: left;
    padding: 0 12px;
    box-sizing: border-box;
    &:last-of-type {
      margin-right: 0;
    }
    .ct_link {
      font-size: 15px;
      font-weight: bold;
      color: #686a6d;
      line-height: 21px;
      &:hover {
        text-decoration: none;
        color: #686a6d;
      }
    }
    .ct_link_disable{
      font-size: 15px;
      font-weight: bold;
      color: #686a6d;
      line-height: 21px;
    }
  }
  .total_ct_list {
    margin-top: 24px;
  }
  .total_ct_dp2 {
    margin-bottom: 10px;
    vertical-align: middle;
    &:last-of-type {
      margin-bottom: 0;
    }
    .ct_link {
      vertical-align: middle;
      font-size: 13px;
      color: #686a6d;
      line-height: 19px;
      font-weight: normal;
      &:hover {
        text-decoration: underline;
        color: #686a6d;
      }
    }
  }
}

.b2b_badge_admin {
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  color: #686a6d;
  background-color: #e5e6e9;
  border-radius: 3px;
}
.b2b_gnb_wrapper {
  width: 1060px;
  margin: 0 auto;
}
.b2b_copyright {
  .b2b_gnb_wrapper {
    position: relative;
    padding: 32px 0;
    border-top: solid 1px #e5e6e9;
  }
  .foot_policy_menu {
    li {
      float:left;
      margin-right: 24px;
      a {
        font-size: 13px;
        color: $gray300;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &:after {
      content:"";
      display:block;
      clear:both;
    }
  }
  .copy_text {
    position: absolute;
    right:0;
    top:32px;
    line-height: 19px;
    font-size: 13px;
    color: $gray300;
  }
}

#B2bGnb {
  position: relative;
  z-index: 11;
}

.b2b_body {
  width: 1060px;
  margin: 0 auto;
  min-height: 500px;
  z-index: 5;
  .partners_alarm {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .page-heading {
    .col-sm-12 {
      padding: 0;
    }
  }
  .page-heading-wrap {
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    .date-setting {
      top: 0;
    }
  }
  .hr_insight_outside {
    width: 1060px !important;
    box-sizing: border-box;
    padding: 40px 0 0;
    .col-xs-6 {
      padding-left: 0;
    }
  }
  .new_dashboard {
    width: 100%;
    margin: 0;
    .col-xs-12 {
      padding: 0;
    }
  }
  .board_section,
  .new_dashboard {
    & > .row {
      margin-left: 0;
      margin-right: 0;
    }
    .m-lr-_15 {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .new_dashboard_hgroup {
    margin: 0 auto 40px;
  }
  .wrapper-content {
    padding: 40px 0 0;
  }
  .page-heading ~ .wrapper-content {
    padding: 0;
  }
  .hr_insight_outside .date-setting-box{
    z-index:3;
  }
}

.b2b_width_full {
  .b2b_body {
    width: 100%;
    margin: 0 auto;
  }
}
.b2b_width_cover {
  .b2b_body {
    width: 1160px;
    margin: 0 auto;
  }
}
.page-heading {
  .col-sm-12 {
    margin-top: 15px;
  }
}

.b2b_gnb_mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.skin-jobplanet {
  .wrapper-content {
    width: 100%;
  }
  .page-heading {
    background-color: transparent;
    width: 100%;
    border-bottom: 0 !important;
    padding: 0;
    margin: 0;
  }
}

.gnb_head {
  .jp_admin_sslogo {
    overflow: hidden;
    display: block;
    font-size: 0;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: solid 1px #e5e6e9;
    img {
      width: 100%;
    }
  }
}
.link_to_btn {
  .link_btn {
    display: block;
    margin-top: 9px;
    margin-left: 8px;
    box-sizing: border-box;
    height: 32px;
    padding: 6px 16px 7px;
    border: 1px solid $gray100;
    border-radius: 6px;
    font-size: 13px;
    line-height: 19px;
    color: $gray400;
    &:hover {
      background-color: $gray50;
    }
  }
}
.gnb_body {
  .other_link {
    position: relative;
    margin-left: -6px;
    display: flex;
    font-size: 0;
    li {
      margin-left: 24px;
      line-height: 64px;
      a {
        vertical-align: middle;
        line-height: 24px;
        font-weight: 500;
        color: $blue500;
      }
      img {
        height: 24px;
      }
      .alri {
        img {
          height: 11px;
        }
      }
    }
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -10px;
      content: "";
      display: block;
      width: 1px;
      height: 17px;
      background-color: $gray100;
    }
  }
}
