
/* Form */
%placeholder {
  &::placeholder {
    color: #c5c7cc;
  }
  &::-webkit-input-placeholder {
    color: #c5c7cc;
  }
  &:-moz-placeholder {
    color: #c5c7cc;
  }
  &::-moz-placeholder {
    color: #c5c7cc;
  }
  &:-ms-input-placeholder {
    color: #c5c7cc;
  }
}

.jply_ip_wrap {
  width: 100%;
  select,
  button,
  input[type="text"],
  input[type="password"],
  input[type="email"] {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  }
}
.jply_ip_label {
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  color: #85878c;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  line-height: 19px;
}
.jply_ip_item_box,
.jply_select_box {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
.jply_ip_ty1,
.jply_ip_sm,
.jply_btn_select,
.jply_btn_select_sm,
.jply_textarea_ty1 {
  display: inline-block;
  width: 100%;
  border: 1px solid #c5c7cc;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #fff;
  color: #323438;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  &:focus {
    border-color: #00c362;
    outline: 0;
  }
  @extend %placeholder;
}
.jply_ip_ty1,
.jply_btn_select {
  height: 48px;
  padding: 13px 16px;
}
.jply_ip_sm,
.jply_btn_select_sm {
  height: 40px;
  padding: 9px 16px;
}

.jply_textarea_ty1 {
  height: 160px;
  padding: 13px 16px;
  resize: none;
  line-height: 24px;
}
.jply_textarea_ty1,
.jply_ip_ty1,
.jply_ip_sm {
  &.read_only {
    cursor: not-allowed;
    background: #f9f9fb;
    color: #686a6d;
    border-color: #e5e6e9;
  }
}
.jply_textarea_ty1:disabled,
.jply_ip_ty1:disabled,
.jply_ip_sm:disabled {
  cursor: not-allowed;
  background: #f9f9fb;
  color: #c5c7cc;
  border-color: #e5e6e9;
}
.is_error {
  .jply_ip_ty1,
  .jply_ip_sm,
  .jply_btn_select,
  .jply_btn_select_sm,
  .jply_textarea_ty1 {
    border-color: #ff5757;
    &:focus {
      border-color: #00c362;
    }
  }
}
.error_text {
  display: inline-block;
  margin-top: 8px;
  color: #ff5757;
  font-size: 13px;
  line-height: 19px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
}

.jply_btn_select,
.jply_btn_select_sm {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:after {
    position: absolute;
    top: 50%;
    right: 14px;
    display: inline-block;
    width: 16px;
    height: 9px;
    margin-top: -3px;
    background: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_form_arrow_down.svg") no-repeat 0 0;
    background-size: 16px 9px;
    content: '';
  }
}
.jply_btn_select {
  padding: 13px 46px 13px 16px;
}
.jply_btn_select_sm {
  padding: 9px 46px 9px 16px;
}
.jply_select_box {
  .placeholder_text {
    color: #c5c7cc;
  }
  select {
    position: absolute;
    box-sizing: content-box;
    top: 0;
    right: 0;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    width: 100%;
    height: 100%;
    display: block;
    border: none;
    cursor: pointer;
    z-index: 1;
    &:focus {
      outline: 0;
      & ~ .jply_btn_select,
      & ~ .jply_btn_select_sm {
        border-color: #00c362;
        color: #323438;
      }
    }
  }
}

.jply_radio_list,
.jply_checkbox_list {
  li {
    display: inline-block;
    margin-right: 32px;
    padding: 12px 0;
    &:last-child {
      margin-right: 0;
    }
  }
}

.jply_radio_box {
  position: relative;
  display: inline-block;
  height: auto;
  min-height: 24px;
  padding-left: 32px;
  box-sizing: border-box;
  .jply_radio_item {
    opacity: 0;
    filter: Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0;
    &:checked {
      &~.radio_icon {
        background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_radio_checked.svg");
      }
    }
  }
  .radio_icon {
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_radio_unchecked.svg");
    background-repeat: no-repeat;
    background-position: 0 0px;
  }
  .radio_text {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
    font-size: 15px;
    line-height: 24px;
    color: #323438;
  }
}

.jply_checkbox_box {
  position: relative;
  display: inline-block;
  height: auto;
  min-height: 24px;
  padding-left: 32px;
  box-sizing: border-box;
  .jply_checkbox_item {
    opacity: 0;
    filter: Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0;
    &:checked {
      &~.checkbox_icon {
        background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/9/ic_checkbox_checked.svg");
      }
    }
  }
  .checkbox_icon {
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/10/ic_checkbox_unchecked.svg");
    background-repeat: no-repeat;
    background-position: 0 0px;
  }
  .checkbox_text {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
    font-size: 15px;
    line-height: 24px;
    color: #323438;
  }
}

.win {
  .jply_ip_ty1,
  .jply_btn_select {
    padding: 11px 16px 12px 16px;
  }
}

/* autocomplete list ys */
.jply_autocomplete_wrapper {
  position: relative;
}
.jply_ui_autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  margin: 2px 0 0;
  padding: 8px 0;
  box-sizing: border-box;
  width: 100%;
  max-height: 336px;
  background-color: white;
  border: solid 1px #c9c9c9;
  overflow-y: auto;
  overflow-x: hidden;
  &.autocomplete_on {
    display: block;
  }
  .jply_ui_autocomplete_item {
    padding: 9px 16px;
    font-size: 15px;
    color: #323438;
    list-style-type: none;
    position:relative;
    cursor: pointer;
    a {
      font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
    }
    .value_none {
      color: #85878c;
    }
    &:hover {
      background-color: #f3f3f4;
      a {
        text-decoration: none;
      }
    }
  }
}
