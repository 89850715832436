/* 190116 jply 모달 버튼 공통 */
.jply_modal_btn {
  display: inline-block;
  margin: 0 8px;
  padding: 14px 24px 16px;
  font-size: 16px;
  border-radius: 3px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  &:hover {
    text-decoration: none;
  }
}
.win {
  .jply_modal_btn {
    padding: 16px 24px 17px;
  }
}
/* 190116 모달 버튼 타입 스타일 */
.jply_btn_ty1 {
  background-color: #00c362;
  color: #fff;
  border: 1px solid #00c362;
  font-weight: bold;
  &:hover {
    background-color: #00a85d;
    border-color: #00a85d;
  }
}
.jply_btn_ty2 {
  background-color: #fff;
  border: 1px solid #c5c7cc;
  color: #85878c;
  &:hover {
    background-color: #f3f3f4;
  }
}

/* 190116 jply 모달 공통 레이아웃 */
.jply_modal {
  position: relative;
  margin: 80px auto 0;
  box-sizing: border-box;
  width: 600px;
  padding: 30px 56px 56px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  z-index: 999;
  .modal_body {
    margin-bottom: 56px;
  }
  .btn_group {
    text-align: center;
  }
  .btn_row {
    text-align: center;
    &::after {
      content:"";
      display: table;
      clear: both;
    }
  }
  .mo_txt_l {
    font-family: 'SpoqaB', '돋움', dotum, Arial, sans-serif;
    font-size: 32px;
    color: #323438;
  }
  .mo_txt_m {
    font-size: 24px;
    color: #85878c;
    font-weight: bold;
  }
  .mo_txt_s {
    font-size: 15px;
    color: #85878c;
  }
  .btn_close_x_ty1 {
    position: absolute;
    top: 0px;
    right: -47px;
    width: 30px;
    height: 30px;
    background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_close_modal_190108.svg) no-repeat center;
    background-size: cover;
  }
  .modal_simple_btn {
    margin-bottom: 16px;
    text-align: left;
    .btn_prev {
      font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
      font-size: 16px;
      color: #85898c;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .jply_modal_btn,
  .jply_ip_label,
  .error_text,
  .jply_simple_tag,
  .mo_txt_s,
  .mo_txt_m {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  }
}
/* 190625 jply 모달 공통 레이아웃 추가 */
.jply_temp{
  &.jply_modal {
    padding: 56px;
  }
  .btn_row {
    text-align: center;
    margin: 0 -8px;
    font-size: 0px;
  }
  .jply_modal_btn {
    box-sizing: border-box;
    width: 235px;
  }
  .half_row_btn {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 0 8px;
    .jply_modal_btn {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
    }
  }
  .modal_header {
    margin-bottom: 40px;
  }
  .modal_body {
    margin-bottom: 0;
  }
  .modal_bottom {
    margin-top: 56px;
  }
  .modal_con_tit {
    font-size: 13px;
    line-height: 19px;
    height: 19px;
    margin-bottom: 8px;
    color: #85878c;
    font-weight: bold;
  }
  .modal_con_panel {
    box-sizing: border-box;
    padding: 18px 20px;
    border: 1px solid #e5e6e8;
    border-radius: 3px;
    background: #fff;
    &.link_to {
      display: block;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
      }
    }
  }
  .modal_con_read {
    height: 360px;
    overflow-y: scroll;
    color: #323438;
    border-radius: 3px;
  }
  .con_box {
    box-sizing: border-box;
    padding: 20px 18px;
    background-color: #f9f9fb;
    font-size: 15px;
    line-height: 24px;
  }
  .con_sec {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .con_text {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .con_subtit {
    margin-bottom: 12px;
    color: #a4a6ad;
  }
  .con_text {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 24px;
    color: #323438;
    font-weight: normal;
  }
  .sub_text {
    font-size: 13px;
    line-height: 18px;
    &.c_orange {
      color: #ffa22b;
    }
    &.c_red {
      color: #ff5757;
    }
    &.c_green {
      color: #00c362;
    }
  }
  .label_state {
    vertical-align: middle;
    margin: 0 0 7px 10px;
    display: inline-block;
    box-sizing: border-box;
    height: 32px;
    padding: 8px 8px 6px;
    font-size: 15px;
    font-weight: normal;
    font-family: 'SpoqaR';
    line-height: 17px;
    border-radius: 3px;
    background-color: #f0fff7;
    color: #00c362;
    &.red {
      background-color: #fff7f7;
      color: #ff5757;
    }
  }
}
