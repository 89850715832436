
/* Color */
$jply_main_dark01: rgba(0, 168, 93, 1);
$jply_maincolor: rgba(0, 195, 98, 1);
$jply_main_light01: rgba(47, 221, 120, 1);
$jply_main_light02: rgba(126, 242, 169, 1);
$jply_main_light03: rgba(217, 255, 230, 1);

$green700: #00A85D;
$green500: #00C362;
$green400: #2FDD78;
$green300: #7EF2A9;
$green100: #D9FFE6;

$jply_sub_dark01: rgba(9, 148, 255, 1);
$jply_subcolor: rgba(64, 182, 255, 1);

$blue500: #0994FF;
$blue400: #40B6FF;

$jply_gray01: rgba(50, 52, 56, 1);
$jply_gray02: rgba(75, 76, 80, 1);
$jply_gray03: rgba(104, 106, 109, 1);
$jply_gray04: rgba(133, 135, 140, 1);
$jply_gray05: rgba(197, 199, 204, 1);
$jply_gray06: rgba(229, 230, 233, 1);
$jply_gray07: rgba(243, 243, 244, 1);

$gray800: #323438;
$gray700: #4B4C50;
$gray500: #686A6D;
$gray400: #85878C;
$gray300: #a4a6ad;
$gray200: #C5C7CC;
$gray100: #E5E6E9;
$gray50: #F3F3F4;
$gray30: #F9F9FB;

$jply_color_red: rgba(255, 87, 87, 1);
$jply_color_orange: rgba(255, 162, 43, 1);
$jply_color_purple: rgba(138, 116, 237, 1);

$red500: #FF5757;
$orange500: #FFA22B;
$purple500: #8A74ED;

.jply_main_dark0 { color: $jply_main_dark01; }
.jply_main { color: $jply_maincolor; }
.jply_main_light0 { color: $jply_main_light01; }
.jply_main_light1 { color: $jply_main_light02; }
.jply_main_light2 { color: $jply_main_light03; }

.jply_sub_dark0 { color: $jply_sub_dark01; }
.jply_sub { color: $jply_subcolor; }

.jply_gr0 { color: $jply_gray01; }
.jply_gr1 { color: $jply_gray02; }
.jply_gr2 { color: $jply_gray03; }
.jply_gr3 { color: $jply_gray04; }
.jply_gr4 { color: $jply_gray05; }
.jply_gr5 { color: $jply_gray06; }
.jply_gr6 { color: $jply_gray07; }

.jply_red { color: $jply_color_red; }
.jply_orange { color: $jply_color_orange; }
.jply_purple { color: $jply_color_purple; }
