
/* Button */
.jply_btn_sm {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 15px;
  line-height: 21px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
}
.jply_btn_md {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 15px;
  line-height: 21px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
}
.jply_btn_lg {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 56px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 16px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
}
.jply_btn_xl {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 18px;
  padding-bottom: 17px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 20px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
}

.jply_disabled {
  opacity: 0.6;
  &:hover,
  &:focus {
    cursor: not-allowed;
  }
}
.ty_default,
.ty_solid,
.ty_line,
.ty_light {
  &:disabled {
    opacity: 0.6;
    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
}

.block {
  display: block;
  width: 100%;
}

.ty_default {
  background-color: transparent;
  border: 1px solid #c5c7cc;
  border-radius: 3px;
  color: #686a6d;
  &:hover,
  &:focus {
    background-color: rgba(133, 135, 140, 0.11);
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  &.active{
    background-color: transparent;
    border: 1px solid #00c362;
    border-radius: 3px;
    color: #00c362;
  }
}
.ty_solid {
  background-color: #00c362;
  border: 1px solid #00c362;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  &:hover,
  &:focus {
    background-color: #00a85d;
    border: 1px solid #00a85d;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: #00c362;
    }
  }
}
.ty_line {
  background-color: transparent;
  border: 1px solid #00c362;
  border-radius: 3px;
  color: #00c362;
  &:hover,
  &:focus {
    background-color: rgba(47, 221, 120, 0.15);
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
.ty_light {
  background-color: rgba(0, 195, 98, 0.13);
  border: 1px solid transparent;
  border-radius: 3px;
  color: #00a85d;
  &:hover,
  &:focus {
    background-color: rgba(0, 195, 98, 0.3);
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: rgba(0, 195, 98, 0.13);
    }
  }
}

/* jply Button Group */
.jply_button_group {
  text-align: center;
  font-size: 0;
  .jply_btn_sm {
    margin: 0 4px;
  }
  .jply_btn_md,
  .jply_btn_lg,
  .jply_btn_xl {
    margin: 0 8px;
  }
}

/* 버튼 내부에 사용되는 left 정렬된 아이콘 */
.ico_left_linkout,
.ico_left_modify {
  padding-left: 43px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 16px 12px;
}
.ico_left_linkout {
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/80/ic_linkout_gray500_sm.svg');
}
.ico_left_modify {
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/100/ic_edit_gray500_sm.svg');
}
