@charset "UTF-8";
body,
select,
input,
textarea,
svg {
  font-family: "Roboto", "Noto Sans Korean", sans-serif !important; }

/* Font */
@font-face {
  font-family: 'SpoqaB';
  src: url("https://common.jobplanet.co.kr/images//fonts/SpoqaHanSansBold.woff2") format("woff2"), url("https://common.jobplanet.co.kr/images//fonts/SpoqaHanSansBold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SpoqaR';
  src: url("https://common.jobplanet.co.kr/images//fonts/SpoqaHanSansRegular.woff2") format("woff2"), url("https://common.jobplanet.co.kr/images//fonts/SpoqaHanSansRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SpoqaL';
  src: url("https://common.jobplanet.co.kr/images//fonts/SpoqaHanSansLight.woff2") format("woff2"), url("https://common.jobplanet.co.kr/images//fonts/SpoqaHanSansLight.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 100;
  src: local("Noto Sans Thin"), local("NotoSans-Thin"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Thin-Hestia.eot"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Thin-Hestia.eot?#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/NotoSansKR-Thin-Hestia.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/NotoSansKR-Thin-Hestia.otf") format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans Light"), local("NotoSans-Light"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Light-Hestia.eot"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Light-Hestia.eot?#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/NotoSansKR-Light-Hestia.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/NotoSansKR-Light-Hestia.otf") format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 350;
  src: local("Noto Sans DemiLight"), local("NotoSans-DemiLight"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-DemiLight-Hestia.eot"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-DemiLight-Hestia.eot?#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/NotoSansKR-DemiLight-Hestia.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/NotoSansKR-DemiLight-Hestia.otf") format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans Regular"), local("NotoSans-Regular"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Regular-Hestia.eot"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Regular-Hestia.eot?#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/NotoSansKR-Regular-Hestia.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/NotoSansKR-Regular-Hestia.otf") format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans Medium"), local("NotoSans-Medium"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Medium-Hestia.eot"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Medium-Hestia.eot?#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/NotoSansKR-Medium-Hestia.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/NotoSansKR-Medium-Hestia.otf") format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans Bold"), local("NotoSans-Bold"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Bold-Hestia.eot"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Bold-Hestia.eot?#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/NotoSansKR-Bold-Hestia.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/NotoSansKR-Bold-Hestia.otf") format("opentype"); }

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 900;
  src: local("Noto Sans Black"), local("NotoSans-Black"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Black-Hestia.eot"), url("https://common.jobplanet.co.kr/fonts/eot/NotoSansKR-Black-Hestia.eot?#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/NotoSansKR-Black-Hestia.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/NotoSansKR-Black-Hestia.otf") format("opentype"); }

@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Thin.woff2") format("woff2"), url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Thin.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/Roboto-Thin.otf") format("opentype"); }

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Light.woff2") format("woff2"), url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Light.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/Roboto-Light.otf") format("opentype"); }

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Regular.woff2") format("woff2"), url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Regular.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/Roboto-Regular.otf") format("opentype"); }

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Medium.woff2") format("woff2"), url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Medium.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/Roboto-Medium.otf") format("opentype"); }

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Bold.woff2") format("woff2"), url("https://common.jobplanet.co.kr/fonts/woff/Roboto-Bold.woff") format("woff"), url("https://common.jobplanet.co.kr/fonts/otf/Roboto-Bold.otf") format("opentype"); }

/* Color */
.jply_main_dark0 {
  color: #00a85d; }

.jply_main {
  color: #00c362; }

.jply_main_light0 {
  color: #2fdd78; }

.jply_main_light1 {
  color: #7ef2a9; }

.jply_main_light2 {
  color: #d9ffe6; }

.jply_sub_dark0 {
  color: #0994ff; }

.jply_sub {
  color: #40b6ff; }

.jply_gr0 {
  color: #323438; }

.jply_gr1 {
  color: #4b4c50; }

.jply_gr2 {
  color: #686a6d; }

.jply_gr3 {
  color: #85878c; }

.jply_gr4 {
  color: #c5c7cc; }

.jply_gr5 {
  color: #e5e6e9; }

.jply_gr6 {
  color: #f3f3f4; }

.jply_red {
  color: #ff5757; }

.jply_orange {
  color: #ffa22b; }

.jply_purple {
  color: #8a74ed; }

/* Button */
.jply_btn_sm {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 15px;
  line-height: 21px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top; }

.jply_btn_md {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 48px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 15px;
  line-height: 21px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top; }

.jply_btn_lg {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 56px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 16px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top; }

.jply_btn_xl {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 64px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 18px;
  padding-bottom: 17px;
  text-align: center;
  font-family: 'SpoqaR' !important;
  font-size: 20px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top; }

.jply_disabled {
  opacity: 0.6; }
  .jply_disabled:hover, .jply_disabled:focus {
    cursor: not-allowed; }

.ty_default:disabled,
.ty_solid:disabled,
.ty_line:disabled,
.ty_light:disabled {
  opacity: 0.6; }
  .ty_default:disabled:hover, .ty_default:disabled:focus,
  .ty_solid:disabled:hover,
  .ty_solid:disabled:focus,
  .ty_line:disabled:hover,
  .ty_line:disabled:focus,
  .ty_light:disabled:hover,
  .ty_light:disabled:focus {
    cursor: not-allowed; }

.block {
  display: block;
  width: 100%; }

.ty_default {
  background-color: transparent;
  border: 1px solid #c5c7cc;
  border-radius: 3px;
  color: #686a6d; }
  .ty_default:hover, .ty_default:focus {
    background-color: rgba(133, 135, 140, 0.11);
    text-decoration: none; }
  .ty_default.jply_disabled:hover, .ty_default.jply_disabled:focus, .ty_default:disabled:hover, .ty_default:disabled:focus {
    background-color: transparent; }
  .ty_default.active {
    background-color: transparent;
    border: 1px solid #00c362;
    border-radius: 3px;
    color: #00c362; }

.ty_solid {
  background-color: #00c362;
  border: 1px solid #00c362;
  border-radius: 3px;
  color: #fff;
  font-weight: bold; }
  .ty_solid:hover, .ty_solid:focus {
    background-color: #00a85d;
    border: 1px solid #00a85d;
    text-decoration: none; }
  .ty_solid.jply_disabled:hover, .ty_solid.jply_disabled:focus, .ty_solid:disabled:hover, .ty_solid:disabled:focus {
    background-color: #00c362; }

.ty_line {
  background-color: transparent;
  border: 1px solid #00c362;
  border-radius: 3px;
  color: #00c362; }
  .ty_line:hover, .ty_line:focus {
    background-color: rgba(47, 221, 120, 0.15);
    text-decoration: none; }
  .ty_line.jply_disabled:hover, .ty_line.jply_disabled:focus, .ty_line:disabled:hover, .ty_line:disabled:focus {
    background-color: transparent; }

.ty_light {
  background-color: rgba(0, 195, 98, 0.13);
  border: 1px solid transparent;
  border-radius: 3px;
  color: #00a85d; }
  .ty_light:hover, .ty_light:focus {
    background-color: rgba(0, 195, 98, 0.3);
    text-decoration: none; }
  .ty_light.jply_disabled:hover, .ty_light.jply_disabled:focus, .ty_light:disabled:hover, .ty_light:disabled:focus {
    background-color: rgba(0, 195, 98, 0.13); }

/* jply Button Group */
.jply_button_group {
  text-align: center;
  font-size: 0; }
  .jply_button_group .jply_btn_sm {
    margin: 0 4px; }
  .jply_button_group .jply_btn_md,
  .jply_button_group .jply_btn_lg,
  .jply_button_group .jply_btn_xl {
    margin: 0 8px; }

/* 버튼 내부에 사용되는 left 정렬된 아이콘 */
.ico_left_linkout,
.ico_left_modify {
  padding-left: 43px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 16px 12px; }

.ico_left_linkout {
  background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/80/ic_linkout_gray500_sm.svg"); }

.ico_left_modify {
  background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/100/ic_edit_gray500_sm.svg"); }

/* Form */
.jply_ip_ty1::placeholder,
.jply_ip_sm::placeholder,
.jply_btn_select::placeholder,
.jply_btn_select_sm::placeholder,
.jply_textarea_ty1::placeholder {
  color: #c5c7cc; }

.jply_ip_ty1::-webkit-input-placeholder,
.jply_ip_sm::-webkit-input-placeholder,
.jply_btn_select::-webkit-input-placeholder,
.jply_btn_select_sm::-webkit-input-placeholder,
.jply_textarea_ty1::-webkit-input-placeholder {
  color: #c5c7cc; }

.jply_ip_ty1:-moz-placeholder,
.jply_ip_sm:-moz-placeholder,
.jply_btn_select:-moz-placeholder,
.jply_btn_select_sm:-moz-placeholder,
.jply_textarea_ty1:-moz-placeholder {
  color: #c5c7cc; }

.jply_ip_ty1::-moz-placeholder,
.jply_ip_sm::-moz-placeholder,
.jply_btn_select::-moz-placeholder,
.jply_btn_select_sm::-moz-placeholder,
.jply_textarea_ty1::-moz-placeholder {
  color: #c5c7cc; }

.jply_ip_ty1:-ms-input-placeholder,
.jply_ip_sm:-ms-input-placeholder,
.jply_btn_select:-ms-input-placeholder,
.jply_btn_select_sm:-ms-input-placeholder,
.jply_textarea_ty1:-ms-input-placeholder {
  color: #c5c7cc; }

.jply_ip_wrap {
  width: 100%; }
  .jply_ip_wrap select,
  .jply_ip_wrap button,
  .jply_ip_wrap input[type="text"],
  .jply_ip_wrap input[type="password"],
  .jply_ip_wrap input[type="email"] {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif; }

.jply_ip_label {
  display: block;
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
  color: #85878c;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  line-height: 19px; }

.jply_ip_item_box,
.jply_select_box {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  box-sizing: border-box; }

.jply_ip_ty1,
.jply_ip_sm,
.jply_btn_select,
.jply_btn_select_sm,
.jply_textarea_ty1 {
  display: inline-block;
  width: 100%;
  border: 1px solid #c5c7cc;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #fff;
  color: #323438;
  font-size: 15px;
  line-height: 20px;
  text-align: left; }
  .jply_ip_ty1:focus,
  .jply_ip_sm:focus,
  .jply_btn_select:focus,
  .jply_btn_select_sm:focus,
  .jply_textarea_ty1:focus {
    border-color: #00c362;
    outline: 0; }

.jply_ip_ty1,
.jply_btn_select {
  height: 48px;
  padding: 13px 16px; }

.jply_ip_sm,
.jply_btn_select_sm {
  height: 40px;
  padding: 9px 16px; }

.jply_textarea_ty1 {
  height: 160px;
  padding: 13px 16px;
  resize: none;
  line-height: 24px; }

.jply_textarea_ty1.read_only,
.jply_ip_ty1.read_only,
.jply_ip_sm.read_only {
  cursor: not-allowed;
  background: #f9f9fb;
  color: #686a6d;
  border-color: #e5e6e9; }

.jply_textarea_ty1:disabled,
.jply_ip_ty1:disabled,
.jply_ip_sm:disabled {
  cursor: not-allowed;
  background: #f9f9fb;
  color: #c5c7cc;
  border-color: #e5e6e9; }

.is_error .jply_ip_ty1,
.is_error .jply_ip_sm,
.is_error .jply_btn_select,
.is_error .jply_btn_select_sm,
.is_error .jply_textarea_ty1 {
  border-color: #ff5757; }
  .is_error .jply_ip_ty1:focus,
  .is_error .jply_ip_sm:focus,
  .is_error .jply_btn_select:focus,
  .is_error .jply_btn_select_sm:focus,
  .is_error .jply_textarea_ty1:focus {
    border-color: #00c362; }

.error_text {
  display: inline-block;
  margin-top: 8px;
  color: #ff5757;
  font-size: 13px;
  line-height: 19px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif; }

.jply_btn_select,
.jply_btn_select_sm {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .jply_btn_select:after,
  .jply_btn_select_sm:after {
    position: absolute;
    top: 50%;
    right: 14px;
    display: inline-block;
    width: 16px;
    height: 9px;
    margin-top: -3px;
    background: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_form_arrow_down.svg") no-repeat 0 0;
    background-size: 16px 9px;
    content: ''; }

.jply_btn_select {
  padding: 13px 46px 13px 16px; }

.jply_btn_select_sm {
  padding: 9px 46px 9px 16px; }

.jply_select_box .placeholder_text {
  color: #c5c7cc; }

.jply_select_box select {
  position: absolute;
  box-sizing: content-box;
  top: 0;
  right: 0;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  cursor: pointer;
  z-index: 1; }
  .jply_select_box select:focus {
    outline: 0; }
    .jply_select_box select:focus ~ .jply_btn_select,
    .jply_select_box select:focus ~ .jply_btn_select_sm {
      border-color: #00c362;
      color: #323438; }

.jply_radio_list li,
.jply_checkbox_list li {
  display: inline-block;
  margin-right: 32px;
  padding: 12px 0; }
  .jply_radio_list li:last-child,
  .jply_checkbox_list li:last-child {
    margin-right: 0; }

.jply_radio_box {
  position: relative;
  display: inline-block;
  height: auto;
  min-height: 24px;
  padding-left: 32px;
  box-sizing: border-box; }
  .jply_radio_box .jply_radio_item {
    opacity: 0;
    filter: Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0; }
    .jply_radio_box .jply_radio_item:checked ~ .radio_icon {
      background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_radio_checked.svg"); }
  .jply_radio_box .radio_icon {
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_radio_unchecked.svg");
    background-repeat: no-repeat;
    background-position: 0 0px; }
  .jply_radio_box .radio_text {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
    font-size: 15px;
    line-height: 24px;
    color: #323438; }

.jply_checkbox_box {
  position: relative;
  display: inline-block;
  height: auto;
  min-height: 24px;
  padding-left: 32px;
  box-sizing: border-box; }
  .jply_checkbox_box .jply_checkbox_item {
    opacity: 0;
    filter: Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0; }
    .jply_checkbox_box .jply_checkbox_item:checked ~ .checkbox_icon {
      background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/9/ic_checkbox_checked.svg"); }
  .jply_checkbox_box .checkbox_icon {
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/10/ic_checkbox_unchecked.svg");
    background-repeat: no-repeat;
    background-position: 0 0px; }
  .jply_checkbox_box .checkbox_text {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
    font-size: 15px;
    line-height: 24px;
    color: #323438; }

.win .jply_ip_ty1,
.win .jply_btn_select {
  padding: 11px 16px 12px 16px; }

/* autocomplete list ys */
.jply_autocomplete_wrapper {
  position: relative; }

.jply_ui_autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  margin: 2px 0 0;
  padding: 8px 0;
  box-sizing: border-box;
  width: 100%;
  max-height: 336px;
  background-color: white;
  border: solid 1px #c9c9c9;
  overflow-y: auto;
  overflow-x: hidden; }
  .jply_ui_autocomplete.autocomplete_on {
    display: block; }
  .jply_ui_autocomplete .jply_ui_autocomplete_item {
    padding: 9px 16px;
    font-size: 15px;
    color: #323438;
    list-style-type: none;
    position: relative;
    cursor: pointer; }
    .jply_ui_autocomplete .jply_ui_autocomplete_item a {
      font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif; }
    .jply_ui_autocomplete .jply_ui_autocomplete_item .value_none {
      color: #85878c; }
    .jply_ui_autocomplete .jply_ui_autocomplete_item:hover {
      background-color: #f3f3f4; }
      .jply_ui_autocomplete .jply_ui_autocomplete_item:hover a {
        text-decoration: none; }

/* 190116 jply 모달 버튼 공통 */
.jply_modal_btn {
  display: inline-block;
  margin: 0 8px;
  padding: 14px 24px 16px;
  font-size: 16px;
  border-radius: 3px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif; }
  .jply_modal_btn:hover {
    text-decoration: none; }

.win .jply_modal_btn {
  padding: 16px 24px 17px; }

/* 190116 모달 버튼 타입 스타일 */
.jply_btn_ty1 {
  background-color: #00c362;
  color: #fff;
  border: 1px solid #00c362;
  font-weight: bold; }
  .jply_btn_ty1:hover {
    background-color: #00a85d;
    border-color: #00a85d; }

.jply_btn_ty2 {
  background-color: #fff;
  border: 1px solid #c5c7cc;
  color: #85878c; }
  .jply_btn_ty2:hover {
    background-color: #f3f3f4; }

/* 190116 jply 모달 공통 레이아웃 */
.jply_modal {
  position: relative;
  margin: 80px auto 0;
  box-sizing: border-box;
  width: 600px;
  padding: 30px 56px 56px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  z-index: 999; }
  .jply_modal .modal_body {
    margin-bottom: 56px; }
  .jply_modal .btn_group {
    text-align: center; }
  .jply_modal .btn_row {
    text-align: center; }
    .jply_modal .btn_row::after {
      content: "";
      display: table;
      clear: both; }
  .jply_modal .mo_txt_l {
    font-family: 'SpoqaB', '돋움', dotum, Arial, sans-serif;
    font-size: 32px;
    color: #323438; }
  .jply_modal .mo_txt_m {
    font-size: 24px;
    color: #85878c;
    font-weight: bold; }
  .jply_modal .mo_txt_s {
    font-size: 15px;
    color: #85878c; }
  .jply_modal .btn_close_x_ty1 {
    position: absolute;
    top: 0px;
    right: -47px;
    width: 30px;
    height: 30px;
    background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_close_modal_190108.svg) no-repeat center;
    background-size: cover; }
  .jply_modal .modal_simple_btn {
    margin-bottom: 16px;
    text-align: left; }
    .jply_modal .modal_simple_btn .btn_prev {
      font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
      font-size: 16px;
      color: #85898c; }
      .jply_modal .modal_simple_btn .btn_prev:hover {
        text-decoration: none; }
  .jply_modal .jply_modal_btn,
  .jply_modal .jply_ip_label,
  .jply_modal .error_text,
  .jply_modal .jply_simple_tag,
  .jply_modal .mo_txt_s,
  .jply_modal .mo_txt_m {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif; }

/* 190625 jply 모달 공통 레이아웃 추가 */
.jply_temp.jply_modal {
  padding: 56px; }

.jply_temp .btn_row {
  text-align: center;
  margin: 0 -8px;
  font-size: 0px; }

.jply_temp .jply_modal_btn {
  box-sizing: border-box;
  width: 235px; }

.jply_temp .half_row_btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 0 8px; }
  .jply_temp .half_row_btn .jply_modal_btn {
    box-sizing: border-box;
    width: 100%;
    margin: 0; }

.jply_temp .modal_header {
  margin-bottom: 40px; }

.jply_temp .modal_body {
  margin-bottom: 0; }

.jply_temp .modal_bottom {
  margin-top: 56px; }

.jply_temp .modal_con_tit {
  font-size: 13px;
  line-height: 19px;
  height: 19px;
  margin-bottom: 8px;
  color: #85878c;
  font-weight: bold; }

.jply_temp .modal_con_panel {
  box-sizing: border-box;
  padding: 18px 20px;
  border: 1px solid #e5e6e8;
  border-radius: 3px;
  background: #fff; }
  .jply_temp .modal_con_panel.link_to {
    display: block;
    text-decoration: none;
    cursor: pointer; }
    .jply_temp .modal_con_panel.link_to:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.jply_temp .modal_con_read {
  height: 360px;
  overflow-y: scroll;
  color: #323438;
  border-radius: 3px; }

.jply_temp .con_box {
  box-sizing: border-box;
  padding: 20px 18px;
  background-color: #f9f9fb;
  font-size: 15px;
  line-height: 24px; }

.jply_temp .con_sec {
  margin-bottom: 32px; }
  .jply_temp .con_sec:last-of-type {
    margin-bottom: 0; }
  .jply_temp .con_sec .con_text:last-of-type {
    margin-bottom: 0; }

.jply_temp .con_subtit {
  margin-bottom: 12px;
  color: #a4a6ad; }

.jply_temp .con_text {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 24px;
  color: #323438;
  font-weight: normal; }

.jply_temp .sub_text {
  font-size: 13px;
  line-height: 18px; }
  .jply_temp .sub_text.c_orange {
    color: #ffa22b; }
  .jply_temp .sub_text.c_red {
    color: #ff5757; }
  .jply_temp .sub_text.c_green {
    color: #00c362; }

.jply_temp .label_state {
  vertical-align: middle;
  margin: 0 0 7px 10px;
  display: inline-block;
  box-sizing: border-box;
  height: 32px;
  padding: 8px 8px 6px;
  font-size: 15px;
  font-weight: normal;
  font-family: 'SpoqaR';
  line-height: 17px;
  border-radius: 3px;
  background-color: #f0fff7;
  color: #00c362; }
  .jply_temp .label_state.red {
    background-color: #fff7f7;
    color: #ff5757; }

@font-face {
  font-family: 'jp-icons-200305';
  src: url("https://common.jobplanet.co.kr/fonts/eot/jp-icons-200305.eot?vupbvd");
  src: url("https://common.jobplanet.co.kr/fonts/eot/jp-icons-200305.eot?vupbvd#iefix") format("embedded-opentype"), url("https://common.jobplanet.co.kr/fonts/woff/jp-icons-200305.woff?vupbvd") format("woff");
  font-weight: normal;
  font-style: normal; }

[class^="jp-"], [class*=" jp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jp-icons-200305' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.jp-quote:before {
  content: "\e900"; }

.jp-star-solid:before {
  content: "\e901"; }

.jp-jobplanet:before {
  content: "\e902"; }

.jp-activity:before {
  content: "\e903"; }

.jp-airplay:before {
  content: "\e904"; }

.jp-alert-circle:before {
  content: "\e905"; }

.jp-alert-octagon:before {
  content: "\e906"; }

.jp-alert-triangle:before {
  content: "\e907"; }

.jp-align-center:before {
  content: "\e908"; }

.jp-align-justify:before {
  content: "\e909"; }

.jp-align-left:before {
  content: "\e90a"; }

.jp-align-right:before {
  content: "\e90b"; }

.jp-anchor:before {
  content: "\e90c"; }

.jp-aperture:before {
  content: "\e90d"; }

.jp-archive:before {
  content: "\e90e"; }

.jp-arrow-down-circle:before {
  content: "\e90f"; }

.jp-arrow-down-left:before {
  content: "\e910"; }

.jp-arrow-down-right:before {
  content: "\e911"; }

.jp-arrow-down:before {
  content: "\e912"; }

.jp-arrow-left-circle:before {
  content: "\e913"; }

.jp-arrow-left:before {
  content: "\e914"; }

.jp-arrow-right-circle:before {
  content: "\e915"; }

.jp-arrow-right:before {
  content: "\e916"; }

.jp-arrow-up-circle:before {
  content: "\e917"; }

.jp-arrow-up-left:before {
  content: "\e918"; }

.jp-arrow-up-right:before {
  content: "\e919"; }

.jp-arrow-up:before {
  content: "\e91a"; }

.jp-at-sign:before {
  content: "\e91b"; }

.jp-award:before {
  content: "\e91c"; }

.jp-bar-chart-2:before {
  content: "\e91d"; }

.jp-bar-chart:before {
  content: "\e91e"; }

.jp-battery-charging:before {
  content: "\e91f"; }

.jp-battery:before {
  content: "\e920"; }

.jp-bell-off:before {
  content: "\e921"; }

.jp-bell:before {
  content: "\e922"; }

.jp-bluetooth:before {
  content: "\e923"; }

.jp-bold:before {
  content: "\e924"; }

.jp-book-open:before {
  content: "\e925"; }

.jp-book:before {
  content: "\e926"; }

.jp-bookmark:before {
  content: "\e927"; }

.jp-box:before {
  content: "\e928"; }

.jp-briefcase:before {
  content: "\e929"; }

.jp-calendar:before {
  content: "\e92a"; }

.jp-camera-off:before {
  content: "\e92b"; }

.jp-camera:before {
  content: "\e92c"; }

.jp-cast:before {
  content: "\e92d"; }

.jp-check-circle:before {
  content: "\e92e"; }

.jp-check-square:before {
  content: "\e92f"; }

.jp-check:before {
  content: "\e930"; }

.jp-chevron-down:before {
  content: "\e931"; }

.jp-chevron-left:before {
  content: "\e932"; }

.jp-chevron-right:before {
  content: "\e933"; }

.jp-chevron-up:before {
  content: "\e934"; }

.jp-chevrons-down:before {
  content: "\e935"; }

.jp-chevrons-left:before {
  content: "\e936"; }

.jp-chevrons-right:before {
  content: "\e937"; }

.jp-chevrons-up:before {
  content: "\e938"; }

.jp-chrome:before {
  content: "\e939"; }

.jp-circle:before {
  content: "\e93a"; }

.jp-clipboard:before {
  content: "\e93b"; }

.jp-clock:before {
  content: "\e93c"; }

.jp-cloud-drizzle:before {
  content: "\e93d"; }

.jp-cloud-lightning:before {
  content: "\e93e"; }

.jp-cloud-off:before {
  content: "\e93f"; }

.jp-cloud-rain:before {
  content: "\e940"; }

.jp-cloud-snow:before {
  content: "\e941"; }

.jp-cloud:before {
  content: "\e942"; }

.jp-code:before {
  content: "\e943"; }

.jp-codepen:before {
  content: "\e944"; }

.jp-codesandbox:before {
  content: "\e945"; }

.jp-coffee:before {
  content: "\e946"; }

.jp-columns:before {
  content: "\e947"; }

.jp-command:before {
  content: "\e948"; }

.jp-compass:before {
  content: "\e949"; }

.jp-copy:before {
  content: "\e94a"; }

.jp-corner-down-left:before {
  content: "\e94b"; }

.jp-corner-down-right:before {
  content: "\e94c"; }

.jp-corner-left-down:before {
  content: "\e94d"; }

.jp-corner-left-up:before {
  content: "\e94e"; }

.jp-corner-right-down:before {
  content: "\e94f"; }

.jp-corner-right-up:before {
  content: "\e950"; }

.jp-corner-up-left:before {
  content: "\e951"; }

.jp-corner-up-right:before {
  content: "\e952"; }

.jp-cpu:before {
  content: "\e953"; }

.jp-credit-card:before {
  content: "\e954"; }

.jp-crop:before {
  content: "\e955"; }

.jp-crosshair:before {
  content: "\e956"; }

.jp-database:before {
  content: "\e957"; }

.jp-delete:before {
  content: "\e958"; }

.jp-disc:before {
  content: "\e959"; }

.jp-dollar-sign:before {
  content: "\e95a"; }

.jp-download-cloud:before {
  content: "\e95b"; }

.jp-download:before {
  content: "\e95c"; }

.jp-droplet:before {
  content: "\e95d"; }

.jp-edit-2:before {
  content: "\e95e"; }

.jp-edit-3:before {
  content: "\e95f"; }

.jp-edit:before {
  content: "\e960"; }

.jp-external-link:before {
  content: "\e961"; }

.jp-eye-off:before {
  content: "\e962"; }

.jp-eye:before {
  content: "\e963"; }

.jp-facebook:before {
  content: "\e964"; }

.jp-fast-forward:before {
  content: "\e965"; }

.jp-feather:before {
  content: "\e966"; }

.jp-figma:before {
  content: "\e967"; }

.jp-file-minus:before {
  content: "\e968"; }

.jp-file-plus:before {
  content: "\e969"; }

.jp-file-text:before {
  content: "\e96a"; }

.jp-file:before {
  content: "\e96b"; }

.jp-film:before {
  content: "\e96c"; }

.jp-filter:before {
  content: "\e96d"; }

.jp-flag:before {
  content: "\e96e"; }

.jp-folder-minus:before {
  content: "\e96f"; }

.jp-folder-plus:before {
  content: "\e970"; }

.jp-folder:before {
  content: "\e971"; }

.jp-framer:before {
  content: "\e972"; }

.jp-frown:before {
  content: "\e973"; }

.jp-gift:before {
  content: "\e974"; }

.jp-git-branch:before {
  content: "\e975"; }

.jp-git-commit:before {
  content: "\e976"; }

.jp-git-merge:before {
  content: "\e977"; }

.jp-git-pull-request:before {
  content: "\e978"; }

.jp-github:before {
  content: "\e979"; }

.jp-gitlab:before {
  content: "\e97a"; }

.jp-globe:before {
  content: "\e97b"; }

.jp-grid:before {
  content: "\e97c"; }

.jp-hard-drive:before {
  content: "\e97d"; }

.jp-hash:before {
  content: "\e97e"; }

.jp-headphones:before {
  content: "\e97f"; }

.jp-heart:before {
  content: "\e980"; }

.jp-help-circle:before {
  content: "\e981"; }

.jp-hexagon:before {
  content: "\e982"; }

.jp-home:before {
  content: "\e983"; }

.jp-image:before {
  content: "\e984"; }

.jp-inbox:before {
  content: "\e985"; }

.jp-info:before {
  content: "\e986"; }

.jp-instagram:before {
  content: "\e987"; }

.jp-italic:before {
  content: "\e988"; }

.jp-key:before {
  content: "\e989"; }

.jp-layers:before {
  content: "\e98a"; }

.jp-layout:before {
  content: "\e98b"; }

.jp-life-buoy:before {
  content: "\e98c"; }

.jp-link-2:before {
  content: "\e98d"; }

.jp-link:before {
  content: "\e98e"; }

.jp-linkedin:before {
  content: "\e98f"; }

.jp-list:before {
  content: "\e990"; }

.jp-loader:before {
  content: "\e991"; }

.jp-lock:before {
  content: "\e992"; }

.jp-log-in:before {
  content: "\e993"; }

.jp-log-out:before {
  content: "\e994"; }

.jp-mail:before {
  content: "\e995"; }

.jp-map-pin:before {
  content: "\e996"; }

.jp-map:before {
  content: "\e997"; }

.jp-maximize-2:before {
  content: "\e998"; }

.jp-maximize:before {
  content: "\e999"; }

.jp-meh:before {
  content: "\e99a"; }

.jp-menu:before {
  content: "\e99b"; }

.jp-message-circle:before {
  content: "\e99c"; }

.jp-message-square:before {
  content: "\e99d"; }

.jp-mic-off:before {
  content: "\e99e"; }

.jp-mic:before {
  content: "\e99f"; }

.jp-minimize-2:before {
  content: "\e9a0"; }

.jp-minimize:before {
  content: "\e9a1"; }

.jp-minus-circle:before {
  content: "\e9a2"; }

.jp-minus-square:before {
  content: "\e9a3"; }

.jp-minus:before {
  content: "\e9a4"; }

.jp-monitor:before {
  content: "\e9a5"; }

.jp-moon:before {
  content: "\e9a6"; }

.jp-more-horizontal:before {
  content: "\e9a7"; }

.jp-more-vertical:before {
  content: "\e9a8"; }

.jp-mouse-pointer:before {
  content: "\e9a9"; }

.jp-move:before {
  content: "\e9aa"; }

.jp-music:before {
  content: "\e9ab"; }

.jp-navigation-2:before {
  content: "\e9ac"; }

.jp-navigation:before {
  content: "\e9ad"; }

.jp-octagon:before {
  content: "\e9ae"; }

.jp-package:before {
  content: "\e9af"; }

.jp-paperclip:before {
  content: "\e9b0"; }

.jp-pause-circle:before {
  content: "\e9b1"; }

.jp-pause:before {
  content: "\e9b2"; }

.jp-pen-tool:before {
  content: "\e9b3"; }

.jp-percent:before {
  content: "\e9b4"; }

.jp-phone-call:before {
  content: "\e9b5"; }

.jp-phone-forwarded:before {
  content: "\e9b6"; }

.jp-phone-incoming:before {
  content: "\e9b7"; }

.jp-phone-missed:before {
  content: "\e9b8"; }

.jp-phone-off:before {
  content: "\e9b9"; }

.jp-phone-outgoing:before {
  content: "\e9ba"; }

.jp-phone:before {
  content: "\e9bb"; }

.jp-pie-chart:before {
  content: "\e9bc"; }

.jp-play-circle:before {
  content: "\e9bd"; }

.jp-play:before {
  content: "\e9be"; }

.jp-plus-circle:before {
  content: "\e9bf"; }

.jp-plus-square:before {
  content: "\e9c0"; }

.jp-plus:before {
  content: "\e9c1"; }

.jp-pocket:before {
  content: "\e9c2"; }

.jp-power:before {
  content: "\e9c3"; }

.jp-printer:before {
  content: "\e9c4"; }

.jp-radio:before {
  content: "\e9c5"; }

.jp-refresh-ccw:before {
  content: "\e9c6"; }

.jp-refresh-cw:before {
  content: "\e9c7"; }

.jp-repeat:before {
  content: "\e9c8"; }

.jp-rewind:before {
  content: "\e9c9"; }

.jp-rotate-ccw:before {
  content: "\e9ca"; }

.jp-rotate-cw:before {
  content: "\e9cb"; }

.jp-rss:before {
  content: "\e9cc"; }

.jp-save:before {
  content: "\e9cd"; }

.jp-scissors:before {
  content: "\e9ce"; }

.jp-search:before {
  content: "\e9cf"; }

.jp-send:before {
  content: "\e9d0"; }

.jp-server:before {
  content: "\e9d1"; }

.jp-settings:before {
  content: "\e9d2"; }

.jp-share-2:before {
  content: "\e9d3"; }

.jp-share:before {
  content: "\e9d4"; }

.jp-shield-off:before {
  content: "\e9d5"; }

.jp-shield:before {
  content: "\e9d6"; }

.jp-shopping-bag:before {
  content: "\e9d7"; }

.jp-shopping-cart:before {
  content: "\e9d8"; }

.jp-shuffle:before {
  content: "\e9d9"; }

.jp-sidebar:before {
  content: "\e9da"; }

.jp-skip-back:before {
  content: "\e9db"; }

.jp-skip-forward:before {
  content: "\e9dc"; }

.jp-slack:before {
  content: "\e9dd"; }

.jp-slash:before {
  content: "\e9de"; }

.jp-sliders:before {
  content: "\e9df"; }

.jp-smartphone:before {
  content: "\e9e0"; }

.jp-smile:before {
  content: "\e9e1"; }

.jp-speaker:before {
  content: "\e9e2"; }

.jp-square:before {
  content: "\e9e3"; }

.jp-star:before {
  content: "\e9e4"; }

.jp-stop-circle:before {
  content: "\e9e5"; }

.jp-sun:before {
  content: "\e9e6"; }

.jp-sunrise:before {
  content: "\e9e7"; }

.jp-sunset:before {
  content: "\e9e8"; }

.jp-tablet:before {
  content: "\e9e9"; }

.jp-tag:before {
  content: "\e9ea"; }

.jp-target:before {
  content: "\e9eb"; }

.jp-terminal:before {
  content: "\e9ec"; }

.jp-thermometer:before {
  content: "\e9ed"; }

.jp-thumbs-down:before {
  content: "\e9ee"; }

.jp-thumbs-up:before {
  content: "\e9ef"; }

.jp-toggle-left:before {
  content: "\e9f0"; }

.jp-toggle-right:before {
  content: "\e9f1"; }

.jp-trash-2:before {
  content: "\e9f2"; }

.jp-trash:before {
  content: "\e9f3"; }

.jp-trello:before {
  content: "\e9f4"; }

.jp-trending-down:before {
  content: "\e9f5"; }

.jp-trending-up:before {
  content: "\e9f6"; }

.jp-triangle:before {
  content: "\e9f7"; }

.jp-truck:before {
  content: "\e9f8"; }

.jp-tv:before {
  content: "\e9f9"; }

.jp-twitter:before {
  content: "\e9fa"; }

.jp-type:before {
  content: "\e9fb"; }

.jp-umbrella:before {
  content: "\e9fc"; }

.jp-underline:before {
  content: "\e9fd"; }

.jp-unlock:before {
  content: "\e9fe"; }

.jp-upload-cloud:before {
  content: "\e9ff"; }

.jp-upload:before {
  content: "\ea00"; }

.jp-user-check:before {
  content: "\ea01"; }

.jp-user-minus:before {
  content: "\ea02"; }

.jp-user-plus:before {
  content: "\ea03"; }

.jp-user-x:before {
  content: "\ea04"; }

.jp-user:before {
  content: "\ea05"; }

.jp-users:before {
  content: "\ea06"; }

.jp-video-off:before {
  content: "\ea07"; }

.jp-video:before {
  content: "\ea08"; }

.jp-voicemail:before {
  content: "\ea09"; }

.jp-volume-1:before {
  content: "\ea0a"; }

.jp-volume-2:before {
  content: "\ea0b"; }

.jp-volume-x:before {
  content: "\ea0c"; }

.jp-volume:before {
  content: "\ea0d"; }

.jp-watch:before {
  content: "\ea0e"; }

.jp-wifi-off:before {
  content: "\ea0f"; }

.jp-wifi:before {
  content: "\ea10"; }

.jp-wind:before {
  content: "\ea11"; }

.jp-x-circle:before {
  content: "\ea12"; }

.jp-x-octagon:before {
  content: "\ea13"; }

.jp-x-square:before {
  content: "\ea14"; }

.jp-x:before {
  content: "\ea15"; }

.jp-youtube:before {
  content: "\ea16"; }

.jp-zap-off:before {
  content: "\ea17"; }

.jp-zap:before {
  content: "\ea18"; }

.jp-zoom-in:before {
  content: "\ea19"; }

.jp-zoom-out:before {
  content: "\ea1a"; }

.jp-badge-new:before {
  content: "\ea1b"; }

.jp-checkbox-checked:before {
  content: "\ea1c"; }

.jp-checkbox-unchecked:before {
  content: "\ea1d"; }

.jp-radio-checked:before {
  content: "\ea1e"; }

.jp-radio-unchecked:before {
  content: "\ea1f"; }

.jp-chevron-left-thin:before {
  content: "\ea20"; }

.jp-chevron-right-thin:before {
  content: "\ea21"; }

.jp-close-thin:before {
  content: "\ea22"; }

.jp-menu-grid:before {
  content: "\ea23"; }

.jp-flag-solid:before {
  content: "\ea24"; }

.jp-bookmark-solid:before {
  content: "\ea25"; }

.jp-heart-solid:before {
  content: "\ea26"; }

.jp-lock-solid:before {
  content: "\ea27"; }

.jp-unlock-solid:before {
  content: "\ea28"; }

.jp-thumbs-up-solid:before {
  content: "\ea29"; }

.jp-thumbs-down-solid:before {
  content: "\ea2a"; }

.jp-toggle-left-solid:before {
  content: "\ea2b"; }

.jp-toggle-right-solid:before {
  content: "\ea2c"; }

.jp-jp-company:before {
  content: "\ea2d"; }

/* 12 grid layout template ys 190116 */
.jply_row {
  margin-bottom: 32px; }
  .jply_row:last-child {
    margin-bottom: 0; }
  .jply_row:after {
    content: "";
    display: table;
    clear: both; }
  .jply_row .jply_col_12 {
    width: 100%; }
  .jply_row .jply_col_6 {
    width: 50%;
    box-sizing: border-box; }
    .jply_row .jply_col_6:first-child {
      float: left;
      padding-right: 16px; }
    .jply_row .jply_col_6:last-child {
      float: right;
      padding-left: 16px; }

/* CommonClass 190116 */
.clearfix:after {
  content: "";
  display: table;
  clear: both; }

/* pagination 190201*/
.jply_pagination_ty1 {
  text-align: center;
  font-size: 0; }
  .jply_pagination_ty1 a,
  .jply_pagination_ty1 .active,
  .jply_pagination_ty1 button,
  .jply_pagination_ty1 .hidden_pages,
  .jply_pagination_ty1 .btn_page {
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    min-width: 32px;
    height: 32px;
    font-size: 16px;
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
    line-height: 32px;
    border-radius: 3px;
    margin-right: 8px; }
    .jply_pagination_ty1 a:last-child,
    .jply_pagination_ty1 .active:last-child,
    .jply_pagination_ty1 button:last-child,
    .jply_pagination_ty1 .hidden_pages:last-child,
    .jply_pagination_ty1 .btn_page:last-child {
      margin-right: 0; }
  .jply_pagination_ty1 a,
  .jply_pagination_ty1 .btn_page {
    color: #85878c; }
    .jply_pagination_ty1 a:hover,
    .jply_pagination_ty1 .btn_page:hover {
      text-decoration: none;
      color: #00c362;
      background-color: rgba(0, 195, 98, 0.13); }
  .jply_pagination_ty1 .active {
    font-weight: bold;
    color: #00c362; }
    .jply_pagination_ty1 .active:hover, .jply_pagination_ty1 .active:focus {
      background-color: transparent;
      cursor: default; }
  .jply_pagination_ty1 .hidden_pages {
    color: #85878c; }
    .jply_pagination_ty1 .hidden_pages:hover, .jply_pagination_ty1 .hidden_pages:focus {
      background-color: transparent;
      cursor: default;
      color: #85878c; }
  .jply_pagination_ty1 .btn_previous,
  .jply_pagination_ty1 .btn_next {
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer; }
  .jply_pagination_ty1 .btn_previous {
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/14/ic_pagination_prev_normal.svg"); }
    .jply_pagination_ty1 .btn_previous:hover {
      background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/13/ic_pagination_prev_hover.svg"); }
  .jply_pagination_ty1 .btn_next {
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/12/ic_pagination_next_normal.svg"); }
    .jply_pagination_ty1 .btn_next:hover {
      background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/11/ic_pagination_next_hover.svg"); }

/* 공통 태그 디자인 */
.jply_simple_tag {
  position: relative;
  vertical-align: top;
  margin: 8px 8px 0 0;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  padding: 12px 41px 12px 15px;
  background: #f3f3f4;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  color: #323438;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .jply_simple_tag .ico_delete {
    position: absolute;
    top: 15px;
    right: 15px;
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/icon_unfollow_181203.svg) no-repeat center;
    background-size: cover;
    cursor: pointer; }

.jply_simple_tag_ty2 {
  position: relative;
  vertical-align: top;
  margin: 8px 8px 0 0;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  padding: 12px 15px;
  background: #f3f3f4;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  color: #323438;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

/* 190403 - 레이아웃 */
/* 템플릿*/
.f_left {
  float: left; }

.f_right {
  float: right; }

.jply_col_1 {
  box-sizing: border-box;
  width: 100%; }

.jply_content {
  margin-bottom: 64px; }

.jply_wrap {
  position: relative;
  width: 100%;
  margin: 0 auto; }
  .jply_wrap:after {
    content: "";
    display: block;
    clear: both; }

.w_fixed {
  width: 1060px; }

.jply_top_ty1 {
  position: relative;
  margin-bottom: 32px; }
  .jply_top_ty1 .wrap:after {
    display: block;
    content: "";
    clear: both; }
  .jply_top_ty1 .title {
    line-height: 40px;
    font-size: 28px;
    font-family: 'SpoqaB';
    display: block; }
  .jply_top_ty1 .text_g {
    font-size: 28px;
    margin-left: 8px;
    color: #a4a6ad;
    font-family: 'SpoqaR'; }
  .jply_top_ty1 .jply_btn_sm:hover {
    text-decoration: none; }

.photo_news_card {
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 3px; }

/* 190403 - 공통 라운드 태그 (채용 카드) */
/* tag */
.jply_rounded_xs {
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  padding: 5px 12px;
  font-size: 13px;
  line-height: 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 15px; }
  .jply_rounded_xs.green500 {
    color: #00C362;
    border-color: #00C362; }
  .jply_rounded_xs.orange500 {
    color: #FFA22B;
    border-color: #FFA22B; }
  .jply_rounded_xs.red500 {
    color: #FF5757;
    border-color: #FF5757; }

/* 190403 - TED 에 추가된 아이콘 */
/* 사용할 태그에서 포지션 위치 선정해 주어야 함 */
.ic_arrow_light_up,
.ic_arrow_light_down {
  background-repeat: no-repeat;
  background-size: 16px 8px; }

.ic_arrow_light_up {
  background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/17/ic_arrow_light_main_up.svg"); }

.ic_arrow_light_down {
  background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/16/ic_arrow_light_main_down.svg"); }

/* TED 코어 컴포넌트 내 정의된 아이콘 */
.jply_ico_btn_prev,
.jply_ico_btn_next {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover; }

.jply_ico_btn_prev {
  width: 26px;
  height: 22px;
  background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/79/ic_linearrow_gray500_lg_prev.svg"); }
  .jply_ico_btn_prev.disabled {
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/77/ic_linearrow_gray200_lg_prev.svg"); }

.jply_ico_btn_next {
  width: 26px;
  height: 22px;
  background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/78/ic_linearrow_gray500_lg_next.svg"); }
  .jply_ico_btn_next.disabled {
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/76/ic_linearrow_gray200_lg_next.svg"); }

/* 20190503 툴팁 추가*/
/* 고정값 : border, shadow, radius, font size, padding */
/* 유동값: width, height, 말풍선 뾰족한 모양의 위치 (min-width: 160)*/
.jply_tooltip_w {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px; }
  .jply_tooltip_w .tip_text {
    box-sizing: border-box;
    min-width: 160px;
    padding: 12px 16px 10px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #E5E6E9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    word-break: keep-all;
    font-size: 14px;
    line-height: 21px;
    color: #4B4C50; }
  .jply_tooltip_w .tip_arrow {
    position: absolute;
    top: 0;
    right: 29px;
    display: block;
    width: 12px;
    height: 9px;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/110/img_tooltip_up.svg");
    background-repeat: no-repeat; }

.win .jply_tooltip_w .tip_text {
  padding: 12px 16px;
  word-break: break-all; }

.jply_linkpanel {
  display: block;
  width: 280px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e6e9;
  background-color: #ffffff; }

.link_group {
  box-sizing: border-box;
  padding: 12px 0; }
  .link_group a {
    display: block;
    box-sizing: border-box;
    padding: 8px 20px;
    line-height: 22px;
    font-size: 15px;
    color: #686A6D;
    background: #fff; }
    .link_group a:hover {
      background: #f3f3f4;
      color: #323438; }
  .link_group .ct_link_disable {
    display: block;
    box-sizing: border-box;
    padding: 8px 20px;
    line-height: 22px;
    font-size: 15px;
    color: #686A6D;
    background: #fff;
    opacity: 0.3; }

.b2b_bi_logo {
  margin: 15px 0;
  position: relative;
  height: 20px;
  display: block; }
  .b2b_bi_logo img {
    height: 100%; }

.b2b_gnb {
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 0 0 #e5e6e9; }
  .b2b_gnb a:hover {
    text-decoration: none; }
  .b2b_gnb .gnb_row {
    width: 100%; }
  .b2b_gnb .row_wrap {
    display: flex;
    width: 1060px;
    margin: 0 auto; }
    .b2b_gnb .row_wrap:after {
      content: "";
      display: block;
      clear: both; }
  .b2b_gnb .b2b_total_category {
    position: absolute;
    z-index: 4;
    width: 100%;
    top: 100%;
    left: 0;
    border-top: solid 1px #e5e6e9;
    background-color: #fff;
    box-shadow: 0 18px 14px 0px rgba(0, 0, 0, 0.1); }

.gnb_head {
  position: relative;
  z-index: 3;
  height: 44px;
  box-sizing: border-box; }

.gnb_body {
  position: relative;
  z-index: 2; }
  .gnb_body .jp_admin_sslogo {
    overflow: hidden;
    display: block;
    font-size: 0;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: solid 1px #e5e6e9; }
    .gnb_body .jp_admin_sslogo img {
      width: 100%; }

.gnb_foot .b2b_total_category,
.gnb_foot .b2b_copyright {
  background-color: #f9f9fb; }

.info_item_more {
  font-size: 15px; }

.b2b_category {
  position: relative; }
  .b2b_category .ct_dp1 {
    display: block;
    float: left;
    height: 64px;
    position: relative;
    margin-right: 30px; }
    .b2b_category .ct_dp1:hover .ct_dp2, .b2b_category .ct_dp1.show .ct_dp2 {
      display: block; }
    .b2b_category .ct_dp1.active {
      position: relative; }
      .b2b_category .ct_dp1.active:after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: -1px;
        width: 100%;
        height: 2px;
        background-color: #00C362; }
      .b2b_category .ct_dp1.active > a {
        text-decoration: none; }
    .b2b_category .ct_dp1 > a {
      font-size: 16px;
      color: #4B4C50;
      line-height: 64px;
      font-weight: 500; }
      .b2b_category .ct_dp1 > a:hover {
        text-decoration: none;
        color: #00C362; }
    .b2b_category .ct_dp1 > .ct_link_disable {
      font-size: 15px;
      color: #686A6D;
      line-height: 64px;
      opacity: 0.3; }
  .b2b_category .ct_dp2 {
    position: absolute;
    display: none;
    top: 100%;
    left: 0; }
  .b2b_category .label_new {
    margin-left: 2px;
    vertical-align: text-top;
    display: inline-block;
    width: 9px;
    height: 8px;
    background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/794/ic_gnb_new_green500_2x.png") no-repeat;
    background-size: 9px 8px; }

.b2b_moreinfo {
  height: 100%;
  display: flex;
  flex-grow: 1;
  position: relative;
  margin-left: 20px;
  margin-top: 4px; }
  .b2b_moreinfo .btn_total_menu {
    overflow: hidden;
    text-indent: -1000px;
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 64px;
    margin-right: 8px;
    border: none;
    background: transparent url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/637/ic_all_menu_gray500_sm.svg") 50% 50% no-repeat; }
    .b2b_moreinfo .btn_total_menu:hover {
      background: transparent url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/638/ic_all_menu_green500_sm.svg") 50% 50% no-repeat; }
  .b2b_moreinfo .alarm_area {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 8px;
    height: 50px; }
    .b2b_moreinfo .alarm_area.show_list .btn_alarm {
      border-radius: 6px;
      background: #F3F3F4; }
    .b2b_moreinfo .alarm_area.show_list .b2b_alarm_list {
      display: block; }
    .b2b_moreinfo .alarm_area .btn_alarm {
      position: relative;
      height: 32px;
      width: 32px;
      background: none;
      border: 0;
      padding-top: 4px; }
      .b2b_moreinfo .alarm_area .btn_alarm i {
        font-size: 16px;
        color: #85878c; }
      .b2b_moreinfo .alarm_area .btn_alarm span {
        font-size: 0;
        line-height: 0; }
      .b2b_moreinfo .alarm_area .btn_alarm.new:after {
        content: "";
        position: absolute;
        right: 4px;
        top: 3px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #00C362; }
    .b2b_moreinfo .alarm_area .b2b_alarm_list {
      position: absolute;
      width: 360px;
      top: 48px;
      right: 0;
      padding-top: 16px;
      display: none; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list #notification_spinner {
        position: relative;
        top: 1px;
        left: 0;
        border-radius: 6px;
        width: 100%;
        background: #fff;
        height: 100px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1); }
        .b2b_moreinfo .alarm_area .b2b_alarm_list #notification_spinner .loading_spinner_img {
          position: relative;
          top: 10px;
          left: 0;
          margin: 0 auto; }
        .b2b_moreinfo .alarm_area .b2b_alarm_list #notification_spinner .loading_spinner_bg {
          position: absolute; }
        .b2b_moreinfo .alarm_area .b2b_alarm_list #notification_spinner .bouncing-loader__round {
          width: 10px;
          height: 10px; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list .inner {
        background: #fff;
        border: 1px solid #E5E6E9;
        border-radius: 6px;
        padding: 16px 0 20px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1); }
      .b2b_moreinfo .alarm_area .b2b_alarm_list .nodata {
        text-align: center;
        tab-size: 16px; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list li:hover {
        background: #F3F3F4; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list li a {
        display: block;
        padding: 16px 20px; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list li .cont {
        position: relative;
        padding-left: 36px; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list li .alarm_logo {
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        overflow: hidden;
        background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/1605/img_noti_pool_1910.png");
        background-size: 100% auto; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list li .tit {
        font-size: 14px;
        color: #686A6D;
        line-height: 22px;
        margin-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 44px; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list li .txt {
        font-size: 12px;
        color: #C5C7CC;
        line-height: 20px; }
      .b2b_moreinfo .alarm_area .b2b_alarm_list .view_all {
        margin-top: 20px;
        text-align: center; }
        .b2b_moreinfo .alarm_area .b2b_alarm_list .view_all button {
          margin-left: 20px; }

.jply_b2b_set {
  position: absolute;
  z-index: 100;
  padding-top: 9px;
  top: 48px;
  right: 0;
  display: none; }

.ct_dp1 > a:focus ~ .ct_dp2 {
  display: block; }

.admin_area {
  position: relative;
  float: left; }
  .admin_area:hover .jply_b2b_set, .admin_area:focus .jply_b2b_set, .admin_area.show .jply_b2b_set {
    display: block; }
  .admin_area:hover .btn_myinfo, .admin_area:focus .btn_myinfo, .admin_area.show .btn_myinfo {
    background-color: #f3f3f4; }
  .admin_area .btn_myinfo {
    position: relative;
    width: 65px;
    height: 48px;
    border-radius: 6px;
    background-color: #fff;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/640/ic_gnb_usermenu_arrow_down_gray200.svg");
    background-position: 47px 50%;
    background-repeat: no-repeat;
    border-color: transparent;
    box-sizing: border-box; }
    .admin_area .btn_myinfo:focus ~ .jply_b2b_set {
      display: block; }
    .admin_area .btn_myinfo .jp_admin_sslogo {
      position: absolute;
      left: 8px;
      top: 8px; }
  .admin_area .info_group {
    padding: 20px 20px;
    border-bottom: solid 1px #e5e6e9; }
    .admin_area .info_group li {
      display: block; }
    .admin_area .info_group .power {
      font-size: 0;
      margin-bottom: 10px; }
      .admin_area .info_group .power > span {
        display: inline-block;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #f3f3f4;
        font-size: 11px;
        font-weight: bold;
        color: #686A6D; }
      .admin_area .info_group .power .premium {
        color: #00C362;
        background: #f0fff7; }
      .admin_area .info_group .power .standard {
        color: #0994FF;
        background: #f2f9ff; }
    .admin_area .info_group .power_company {
      word-break: break-all;
      line-height: 19px;
      color: #686A6D;
      margin-bottom: 4px; }
    .admin_area .info_group .power_name {
      line-height: 22px;
      font-size: 15px;
      font-weight: bold;
      color: #323438;
      word-break: break-all; }

.b2b_total_category {
  position: relative;
  z-index: 1; }
  .b2b_total_category .b2b_gnb_wrapper {
    position: relative;
    padding: 32px 0 40px; }
    .b2b_total_category .b2b_gnb_wrapper:after {
      content: "";
      display: block;
      clear: both; }
  .b2b_total_category .total_ct_dp1 {
    float: left;
    padding: 0 12px;
    box-sizing: border-box; }
    .b2b_total_category .total_ct_dp1:last-of-type {
      margin-right: 0; }
    .b2b_total_category .total_ct_dp1 .ct_link {
      font-size: 15px;
      font-weight: bold;
      color: #686a6d;
      line-height: 21px; }
      .b2b_total_category .total_ct_dp1 .ct_link:hover {
        text-decoration: none;
        color: #686a6d; }
    .b2b_total_category .total_ct_dp1 .ct_link_disable {
      font-size: 15px;
      font-weight: bold;
      color: #686a6d;
      line-height: 21px; }
  .b2b_total_category .total_ct_list {
    margin-top: 24px; }
  .b2b_total_category .total_ct_dp2 {
    margin-bottom: 10px;
    vertical-align: middle; }
    .b2b_total_category .total_ct_dp2:last-of-type {
      margin-bottom: 0; }
    .b2b_total_category .total_ct_dp2 .ct_link {
      vertical-align: middle;
      font-size: 13px;
      color: #686a6d;
      line-height: 19px;
      font-weight: normal; }
      .b2b_total_category .total_ct_dp2 .ct_link:hover {
        text-decoration: underline;
        color: #686a6d; }

.b2b_badge_admin {
  vertical-align: middle;
  display: inline-block;
  margin-left: 4px;
  padding: 0 4px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  color: #686a6d;
  background-color: #e5e6e9;
  border-radius: 3px; }

.b2b_gnb_wrapper {
  width: 1060px;
  margin: 0 auto; }

.b2b_copyright .b2b_gnb_wrapper {
  position: relative;
  padding: 32px 0;
  border-top: solid 1px #e5e6e9; }

.b2b_copyright .foot_policy_menu li {
  float: left;
  margin-right: 24px; }
  .b2b_copyright .foot_policy_menu li a {
    font-size: 13px;
    color: #a4a6ad; }
    .b2b_copyright .foot_policy_menu li a:hover {
      text-decoration: none; }

.b2b_copyright .foot_policy_menu:after {
  content: "";
  display: block;
  clear: both; }

.b2b_copyright .copy_text {
  position: absolute;
  right: 0;
  top: 32px;
  line-height: 19px;
  font-size: 13px;
  color: #a4a6ad; }

#B2bGnb {
  position: relative;
  z-index: 11; }

.b2b_body {
  width: 1060px;
  margin: 0 auto;
  min-height: 500px;
  z-index: 5; }
  .b2b_body .partners_alarm {
    width: 100%;
    padding: 0;
    margin: 0; }
  .b2b_body .page-heading .col-sm-12 {
    padding: 0; }
  .b2b_body .page-heading-wrap:after {
    content: "";
    display: block;
    clear: both; }
  .b2b_body .page-heading-wrap .date-setting {
    top: 0; }
  .b2b_body .hr_insight_outside {
    width: 1060px !important;
    box-sizing: border-box;
    padding: 40px 0 0; }
    .b2b_body .hr_insight_outside .col-xs-6 {
      padding-left: 0; }
  .b2b_body .new_dashboard {
    width: 100%;
    margin: 0; }
    .b2b_body .new_dashboard .col-xs-12 {
      padding: 0; }
  .b2b_body .board_section > .row,
  .b2b_body .new_dashboard > .row {
    margin-left: 0;
    margin-right: 0; }
  .b2b_body .board_section .m-lr-_15,
  .b2b_body .new_dashboard .m-lr-_15 {
    margin-left: -15px;
    margin-right: -15px; }
  .b2b_body .new_dashboard_hgroup {
    margin: 0 auto 40px; }
  .b2b_body .wrapper-content {
    padding: 40px 0 0; }
  .b2b_body .page-heading ~ .wrapper-content {
    padding: 0; }
  .b2b_body .hr_insight_outside .date-setting-box {
    z-index: 3; }

.b2b_width_full .b2b_body {
  width: 100%;
  margin: 0 auto; }

.b2b_width_cover .b2b_body {
  width: 1160px;
  margin: 0 auto; }

.page-heading .col-sm-12 {
  margin-top: 15px; }

.b2b_gnb_mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.skin-jobplanet .wrapper-content {
  width: 100%; }

.skin-jobplanet .page-heading {
  background-color: transparent;
  width: 100%;
  border-bottom: 0 !important;
  padding: 0;
  margin: 0; }

.gnb_head .jp_admin_sslogo {
  overflow: hidden;
  display: block;
  font-size: 0;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: solid 1px #e5e6e9; }
  .gnb_head .jp_admin_sslogo img {
    width: 100%; }

.link_to_btn .link_btn {
  display: block;
  margin-top: 9px;
  margin-left: 8px;
  box-sizing: border-box;
  height: 32px;
  padding: 6px 16px 7px;
  border: 1px solid #E5E6E9;
  border-radius: 6px;
  font-size: 13px;
  line-height: 19px;
  color: #85878C; }
  .link_to_btn .link_btn:hover {
    background-color: #F3F3F4; }

.gnb_body .other_link {
  position: relative;
  margin-left: -6px;
  display: flex;
  font-size: 0; }
  .gnb_body .other_link li {
    margin-left: 24px;
    line-height: 64px; }
    .gnb_body .other_link li a {
      vertical-align: middle;
      line-height: 24px;
      font-weight: 500;
      color: #0994FF; }
    .gnb_body .other_link li img {
      height: 24px; }
    .gnb_body .other_link li .alri img {
      height: 11px; }
  .gnb_body .other_link:before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -10px;
    content: "";
    display: block;
    width: 1px;
    height: 17px;
    background-color: #E5E6E9; }
