@import "_fonts";
@import "_color";
@import "_button";
@import "_form";
@import "_modal";
@import "_icons";

/* 12 grid layout template ys 190116 */
.jply_row {
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
  &:after{
    content:"";
    display: table;
    clear: both;
  }
  .jply_col_12 {
    width: 100%;
  }
  .jply_col_6 {
    width: 50%;
    box-sizing: border-box;
    &:first-child {
      float: left;
      padding-right: 16px;
    }
    &:last-child {
      float: right;
      padding-left: 16px;
    }
  }
}
/* CommonClass 190116 */
.clearfix {
  &:after{
    content:"";
    display: table;
    clear: both;
  }
}

/* pagination 190201*/
.jply_pagination_ty1 {
  text-align: center;
  font-size: 0;
  a,
  .active,
  button,
  .hidden_pages,
  .btn_page {
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    min-width: 32px;
    height: 32px;
    font-size: 16px;
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
    line-height: 32px;
    border-radius: 3px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  a,
  .btn_page {
    color: $jply_gray04;
    &:hover {
      text-decoration: none;
      color: $jply_maincolor;
      background-color: rgba(0, 195, 98, 0.13);
    }
  }
  .active {
    font-weight: bold;
    color: $jply_maincolor;
    &:hover,
    &:focus {
      background-color: transparent;
      cursor: default;
    }
  }
  .hidden_pages {
    color: $jply_gray04;
    &:hover,
    &:focus {
      background-color: transparent;
      cursor: default;
      color: $jply_gray04;
    }
  }
  .btn_previous,
  .btn_next {
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .btn_previous {
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/14/ic_pagination_prev_normal.svg");
    &:hover {
      background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/13/ic_pagination_prev_hover.svg");
    }
  }
  .btn_next {
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/12/ic_pagination_next_normal.svg");
    &:hover {
      background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/11/ic_pagination_next_hover.svg");
    }
  }
}
/* 공통 태그 디자인 */
.jply_simple_tag {
  position: relative;
  vertical-align: top;
  margin: 8px 8px 0 0;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  padding: 12px 41px 12px 15px;
  background: #f3f3f4;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  color: #323438;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .ico_delete {
    position: absolute;
    top: 15px;
    right:15px;
    display: inline-block;
    width: 11px;
    height: 11px;
    background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/icon_unfollow_181203.svg) no-repeat center;
    background-size: cover;
    cursor: pointer;
  }
}
.jply_simple_tag_ty2 {
  position: relative;
  vertical-align: top;
  margin: 8px 8px 0 0;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  padding: 12px 15px;
  background: #f3f3f4;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif;
  color: #323438;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/* 190403 - 레이아웃 */
/* 템플릿*/
.f_left {
  float: left;
}
.f_right {
  float: right;
}
.jply_col_1 {
  box-sizing: border-box;
  width: 100%;
}
.jply_content {
  margin-bottom: 64px;
}
.jply_wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.w_fixed {
  width: 1060px;
}
.jply_top_ty1 {
  position: relative;
  margin-bottom: 32px;
  .wrap{
    &:after{
      display: block;
      content: "";
      clear: both;
    }
  }
  .title {
    line-height: 40px;
    font-size: 28px;
    font-family: 'SpoqaB';
    display: block;
  }
  .text_g {
    font-size: 28px;
    margin-left: 8px;
    color: #a4a6ad;
    font-family: 'SpoqaR';
  }
  .jply_btn_sm {
    &:hover {
      text-decoration: none;
    }
  }
}

.photo_news_card{
  display: block;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
}
/* 190403 - 공통 라운드 태그 (채용 카드) */
/* tag */
.jply_rounded_xs {
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  padding: 5px 12px;
  font-size: 13px;
  line-height: 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 15px;
  &.green500 {
    color: $green500;
    border-color: $green500;
  }
  &.orange500 {
    color: $orange500;
    border-color: $orange500;
  }
  &.red500 {
    color: $red500;
    border-color: $red500;
  }
}

/* 190403 - TED 에 추가된 아이콘 */
/* 사용할 태그에서 포지션 위치 선정해 주어야 함 */
.ic_arrow_light_up,
.ic_arrow_light_down {
  background-repeat: no-repeat;
  background-size: 16px 8px;
}
.ic_arrow_light_up {
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/17/ic_arrow_light_main_up.svg');
}
.ic_arrow_light_down {
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/16/ic_arrow_light_main_down.svg');
}

/* TED 코어 컴포넌트 내 정의된 아이콘 */
.jply_ico_btn_prev,
.jply_ico_btn_next {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}

.jply_ico_btn_prev {
  width: 26px;
  height: 22px;
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/79/ic_linearrow_gray500_lg_prev.svg');
  &.disabled {
    background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/77/ic_linearrow_gray200_lg_prev.svg');
  }
}

.jply_ico_btn_next {
  width: 26px;
  height: 22px;
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/78/ic_linearrow_gray500_lg_next.svg');
  &.disabled {
    background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/76/ic_linearrow_gray200_lg_next.svg');
  }
}

/* 20190503 툴팁 추가*/
/* 고정값 : border, shadow, radius, font size, padding */
/* 유동값: width, height, 말풍선 뾰족한 모양의 위치 (min-width: 160)*/
.jply_tooltip_w {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 8px;
  .tip_text {
    box-sizing: border-box;
    min-width: 160px;
    padding: 12px 16px 10px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid $gray100;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    word-break: keep-all;
    font-size: 14px;
    line-height: 21px;
    color: $gray700;
  }
  .tip_arrow {
    position: absolute;
    top: 0;
    right: 29px;
    display: block;
    width: 12px;
    height: 9px;
    background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/110/img_tooltip_up.svg');
    background-repeat: no-repeat;
  }
}
.win {
  .jply_tooltip_w {
    .tip_text {
      padding: 12px 16px;
      word-break: break-all;
    }
  }
}
