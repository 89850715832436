@media print {
   h1 {
      color: #000;
      background: none;
   }
   .insgnb,
   .navbar-default {
      display: none;
   }
   #page-wrapper{
   	margin: 0 !important;
   	border: none !important;
   	.alri_contents{
   		width: 100% !important;
   	}
   }
}
@page {
  size: A4;
}