@charset "utf-8";

// jp_skin Common style
@import "../jp_skin.scss";
@import "./iCheck/custom.scss";

/* alri helper add */
.col-m-b {
  margin-bottom: 35px;
}
